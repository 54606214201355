import $ from 'jquery';


function initImageGroup(parentElement)
{
    const parent = $(parentElement);
    let slideInterval = parent.data("delay");
    let startDelay = parent.data("startDelay");
    const images = parent.children('img').filter(function(){
        return !$(this).hasClass('mc-no-slide');
    });
    let currentImageIndex = 0;

    function hideAllImages()
    {
        images.each( function(index) {
            this.style.opacity = 0;
        })
    }

    function showImage(imageIndex)
    {
        images.each( function(index) {
            this.style.opacity = (index === imageIndex) ? 1 : 0;
        })

    //     images.eq(0).fadeTo(250, 1);
    //     images.eq(1).fadeTo(250, 0);
    }

    function showNextImage() {
        if(++currentImageIndex >= images.length)
        {
            currentImageIndex = 0;
        }
        showImage(currentImageIndex);
    }

    hideAllImages()
    showImage(currentImageIndex);

    slideInterval = slideInterval ? slideInterval : 5000;
    startDelay = startDelay ? startDelay : slideInterval;
    // console.log(`found ${images.length} images`);
    setTimeout(function(){
        showNextImage();
        // now enter standard interval changes
        setInterval(function(){
            showNextImage();
        }, slideInterval);
    }, startDelay);
}

export function initSlider()
{
    const sliders = $('.mc-slider'); 

    sliders.each( function(){
        initImageGroup(this);
    });

}
