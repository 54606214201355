import $ from 'jquery';

const discountDuration = 1000*60*30;    // length of time discount will be active
let countdownTimer = null;

export function checkForDiscount()
{
    const url = new URL(window.location.href);
    const discountCode = url.searchParams.get('discount');

    // localStorage.clear();

    if(isDicountCodeValid(discountCode) && !isDiscountActive())
    {
        activateDiscount(discountCode);
    }

    // check if a code is now active, if so hook all links to shop
    if(isDiscountActive())
    {
        $('div.discount').show(500);
        installCountdownDisplay();
        installDiscountClickHandler();
        installTeletyper('div.discount h3', buildDiscountMessage());
    }
}

function buildDiscountMessage()
{
    let discountAmount = '';
    const discountCta = 'Coupon Available, Click Button to Add Coupon to Cart.';

    let code = localStorage.getItem('code');
    if(code)
    {
        let lastTwoChars = code.slice(-2);
        if(lastTwoChars === 'FS') {
            discountAmount = 'Free Shipping ';
        }
        else
        {
            let percent = parseInt(lastTwoChars, 10);
            if(typeof percent === 'number' && percent)
            {
                discountAmount = `${percent}% `;
            }
        }
    }
    return discountAmount + discountCta;

}

/**
 * @returns {boolean} state indicating if current discount is currently active
 */
function isDiscountActive()
{
    let timeStart = localStorage.getItem('time');
    if(!timeStart) {
        return false;
    }
    // now check if timer has expired
    let timeDelta = Date.now() - timeStart;
    return (timeDelta < discountDuration);
}

/**
 * Activate's a dicount code, resets timer and stores in local storage
 * @param {string} discountCode 
 */
function activateDiscount(discountCode)
{
    // console.log('activating new code');
    localStorage.setItem( 'code', discountCode);
    localStorage.setItem( 'time', Date.now());
}

/**
 * Determin if a given discount code is valid
 * @param {string} discountCode
 * @returns {boolean} true if given discount code is valid
 */
function isDicountCodeValid(discountCode)
{
    return ((typeof discountCode === 'string') &&
            (discountCode.length >= 3) &&
            (discountCode.length < 16) );
}

function hasDiscountBeenClaimed()
{
    let code = localStorage.getItem('code');
    return (isDicountCodeValid(code) && code === localStorage.getItem('claimed'));
}

/**
 * Installs an interval driven countdown display
 * Disables interval timers displaying discount expired message at end of countdown 
 */
function installCountdownDisplay()
{
    if(hasDiscountBeenClaimed()) {
        $('button.discount-button').html('SUCCESS, DISCOUNT CLAIMED');
        return;
    }

    // set current time
    const timeStart = parseInt(localStorage.getItem('time'), 10);
    const countdownSpan = $('span.discount-time-remaining');

    const updateSpan = function()
    {
        let  msRemaining = discountDuration - (Date.now() - timeStart);
        if(msRemaining <= 0)
        {
            clearInterval(countdownTimer);
            $('button.discount-button').html('DISCOUNT EXPIRED');
            return;
        }
        let minutes = Math.floor(msRemaining / (1000*60));
        let seconds = Math.floor((msRemaining-minutes*1000*60) / 1000);

        countdownSpan.html(`${minutes.toString().padStart(2,'0')}:${seconds.toString().padStart(2,'0')}`);
    }

    if(countdownSpan)
    {
        updateSpan();
        countdownTimer = setInterval(updateSpan, 1000);
    }
}

/**
 * Install a click handler for the button used to claim the discount
 * Redirects to the shopify store with code
 */
function installDiscountClickHandler()
{
    $('button.discount-button').on('click', function(){
        if(hasDiscountBeenClaimed() || isDiscountActive())
        {
            const url = `https://shop.mdshield.com/discount/${localStorage.getItem('code')}`;
            localStorage.setItem('claimed', localStorage.getItem('code'));
            window.location.href = url;
            return false;
        }
        // code is expired
        return false;        
    });
}

/**
 * 
 * @param {string} selector     - Selector of elemenet to inject message into 
 * @param {string} message      - Message 
 */
function installTeletyper(selector, message)
{
    let pos = 0;                    // position in message
    const standardCharDelay = 5;   // delay on each char, in video frames
    let cursorRepeat = 15;          // if non zero, flash cursor this many times, even = cursor on
    let delay = standardCharDelay;
    let frameCounter = 0;
    const el = $(selector);

    const updateHtml = function()
    {
        let cursorChar = (((frameCounter>>3)&1)===0) ? '_' : '<span style="opacity:0;">_</span>';
        let out = message.slice(0, pos);
        if(message.length > pos)
        {
            out += cursorChar;
        }
        el.html(out);
    };  

    const typer = function()
    {
        frameCounter++;
        if(--delay <= 0)
        {
            delay = standardCharDelay;
            // are we blinking a cursor?
            if(cursorRepeat)
            {
                // yup we are blinking a cursor
                cursorRepeat--;
            }
            else
            {
                pos++;
                if(pos)
                {
                    if(message.charAt(pos-1) === ',')
                    {
                        cursorRepeat = 10;
                    }
                }
            }
        }
        updateHtml();
        if(pos < message.length)
        {
            requestAnimationFrame(typer);
        }
    };

    requestAnimationFrame(typer);

}