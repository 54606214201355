import $ from 'jquery';
import 'bootstrap';
import { initSlider } from './mc-slider.js';
import { checkForDiscount } from './discounts.js';

// function initContactFormModal()
// {
//         const modal = $('#modal-contact');
//         const form = modal.find('#contact-form');
    
//         function resetForm()
//         {
//             form.removeClass("was-validated");
//             form.find('#contact-form-name').val('');
//             form.find('#contact-form-email').val('');
//             form.find('#contact-form-subject').val('');
//             form.find('#contact-form-body').val('');
//             form.show();
//             modal.find('#contact-form-success').hide();
//         }
    
//         function handleContactSubmit(event){
//             // const form = $(this);
//             event.preventDefault();
//             event.stopPropagation();
    
//             let status = form[0].checkValidity();
//             if(status)
//             {
//                 $.post('submit', form.serialize(), (reply) => {
//                     if(reply.success)
//                     {
//                         form.hide(400);
//                         modal.find('#contact-form-success').show(400);
//                     }
//                 }, 'json');
//             }
//             else{
//                 form.addClass("was-validated");
//             }
//             return false;
//         };

//         // set launch event on contact anchor item
//         $('#js-launch-contact').on('click', (event) => {
//             modal.modal('show');
//             return false;
//         });
    
//         modal.on('hidden.bs.modal', (event) => {
//             resetForm();
//         });
    
//         modal.on('shown.bs.modal', (event) => {
//             form.find('#contact-form-name').focus();
//         });
    
    
//         if(form)
//         {
//             form.on('submit', handleContactSubmit );
//         }
    
// }

//document.addEventListener('DOMContentLoaded',
$(document).ready(
    function(){

        checkForDiscount();
        
        initSlider();
        // initContactFormModal();

    });
    